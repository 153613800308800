/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStartedCenter from 'components/GetStartedCenter';
// import DemoModal from 'components/DemoModal';

// import { Main } from 'styles/blog';
// import { VideoPost } from 'styles/videoPost';
import 'styles/blog.scss';
import 'styles/videoPost.scss';
import { Link } from 'gatsby';
const ProductVideos = ({ location }) => {
  const [youtubeData, setYoutubeData] = useState([]);

  useEffect(() => {
    let isMounted = true;
    fetch(
      `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${process.env.GATSBY_PLAYLIST_ID}&key=${process.env.GATSBY_YOUTUBE_API_KEY}`,
    )
      .then((res) => res.json())
      .then((data) => {
        if (isMounted) {
          setYoutubeData(data.items);
        }
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const [showVideoModal, setVideoModal] = useState(false);
  const [demoVideo, setDemoVideo] = useState(
    'https://www.youtube-nocookie.com/embed/vrff3vYs93I',
  );

  const toggleVideoModal = useCallback(
    (url) => {
      setDemoVideo(`https://www.youtube-nocookie.com/embed/${url}`);
      setVideoModal(!showVideoModal);
    },
    [showVideoModal],
  );

  const clickHandler = useCallback((e) => {
    if (e.target.id === 'demo-modal') setVideoModal(false);
  }, []);

  useEffect(() => {
    window.addEventListener('click', clickHandler);

    return () => window.removeEventListener('click', clickHandler);
  }, [clickHandler]);

  useEffect(() => {
    document.body.style.overflow = showVideoModal ? 'hidden' : 'auto';
  }, [showVideoModal]);

  return (
    <Layout location={location}>
      {({ toggleSignup }) => (
        <>
          <Meta
            title="Product Updates | ruttl"
            description="Improve your knowledge and stay updated with new trends in designing with ruttl's blog page that is regularly updated with latest content on web designing."
            url="https://ruttl.com/product-updates/"
          />
          <div
            id="demo-modal"
            className={cn(['demo-modal', { show: showVideoModal }])}>
            <button
              type="button"
              className="button-unstyled"
              onClick={toggleVideoModal}>
              <img src="/assets/img/close.svg" alt="close" />
            </button>
            {showVideoModal && (
              <iframe
                width="560"
                height="315"
                src={demoVideo}
                frameBorder="0"
                title="ruttl demo"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </div>
          <main className="blog-styled-main">
            <div className="blog-filters-main">
              <div className="container">
                <div className="filter-block">
                  <Link to="/blog/">All blogs</Link>
                  <Link to="/product-updates/">Product Updates</Link>
                  <Link to="/product-videos/" className="active">
                    Product Videos
                  </Link>
                  <Link to="/customer-stories/">Customer Stories</Link>
                </div>
                <h1 className="product-updates-heading">Product Videos</h1>
                {/* <div className="filter-block">
                  <button
                    className={`${category === 'Product Updates' ? 'active' : ''}`}
                    type="button"
                    onClick={categoryToggle('Product Updates')}>
                    Product Updates
                  </button>
                  <button
                    className={`${category === 'Product Videos' ? 'active' : ''}`}
                    type="button"
                    onClick={categoryToggle('Product Videos')}>
                    Product Videos
                  </button>
                </div> */}
              </div>
            </div>
            <section className="post-list-main product-posts">
              {/* <div className="container">
              <p className="bold post-list-main--subhead">
                Our thoughts and observations on business, partners, nutrients,
                productivity, Science, &amp; more.
              </p>
            </div> */}
              {true && (
                <div className="container">
                  <div className="flex flex-wrap" style={{ margin: '0 -15px' }}>
                    {youtubeData?.map((item) => (
                      <React.Fragment key={item?.id}>
                        {/* <div
                            link={`https://www.youtube.com/watch?v=/${item.snippet.resourceId.videoId}/`}
                            title={item.snippet.title}
                            image={item.snippet.thumbnails}
                            excerpt={item.snippet.description}
                            category='Product Videos'
                            noDetail
                            date={item.snippet.publishedAt}
                            author={item.snippet.videoOwnerChannelTitle}
                            // authorImage={node.author.image.fluid}
                          /> */}
                        <button
                          type="button"
                          className="button-transparent video-post-btn"
                          onClick={() => {
                            toggleVideoModal(
                              item?.snippet?.resourceId?.videoId,
                            );
                          }}>
                          <img
                            src={
                              item?.snippet?.thumbnails?.maxres?.url ||
                              item?.snippet?.thumbnails?.standard?.url ||
                              item?.snippet?.thumbnails?.medium?.url ||
                              item?.snippet?.thumbnails?.default?.url
                            }
                            alt={item.snippet.title}
                            className="banner-thumb"
                          />
                          <img
                            className="play-button"
                            src="/assets/img/play.svg"
                            alt=""
                          />
                        </button>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              )}
            </section>
            <GetStartedCenter toggleSignup={toggleSignup} />
          </main>
        </>
      )}
    </Layout>
  );
};

ProductVideos.propTypes = {
  location: PropTypes.object,
};

export default ProductVideos;
